@import url("../colors.css");

.talk-to-us-sec {
  width: 600px;
  margin: 0 auto;
}

.talk-to-us-sec .title {
  font-size: 28px;
}

.theme-btn-primary {
  border: 1px solid var(--accent-bg);
  border-radius: 22px;
  color: var(--normal-bg);
  background-color: var(--accent-bg);
}

.theme-btn-primary:hover {
  border: 1px solid var(--accent-bg);
  color: var(--accent-text);
}

.talk-to-us-sec .sub-title {
  font-size: 15px;
  color: var(--normal-text);
}

.talk-to-us-sec .desc {
  color: var(--normal-text);
  font-size: 22px;
}

.talk-to-us-sec img {
  max-width: 150px;
  margin-left: auto;
  display: block;
}

span.sec-title {
  font-size: 150px;
  text-transform: uppercase;
  color: var(--accent-text);
}
