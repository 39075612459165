.single-project-summary {
  padding-bottom: 20px;
}

.single-project-summary .text-table-box p {
  line-height: 1.5;
}
.single-project-summary .text-table-box,
.single-project-summary .text-table-box ~ figure.table {
  width: 60%;
  max-width: 700px;
  margin: 0 auto;
}

.single-project-summary .text-table-box ~ figure.table table {
  width: 100%;
}

.single-project-summary .text-table-box ~ figure.table table,
.single-project-summary .text-table-box ~ figure.table td,
.single-project-summary .text-table-box ~ figure.table tr,
.single-project-summary .text-table-box ~ figure.table th {
  border: none;
  vertical-align: top;
}

.single-project-summary .text-table-box ~ figure.table p {
  margin-bottom: 8px;
}

.single-project-summary p {
  line-height: 1.7;
}
.single-project-summary .left-padding-box {
  max-width: 600px;
  margin-left: 30%;
}

.single-project-summary .between-big-text {
  max-width: 900px;
  margin: 0 auto;
}
