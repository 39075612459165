@import url("../../colors.css");

.ct-video-player .play-btn {
  position: absolute;
  left: 50px;
  bottom: 50px;
  transform: translate(-50%, -50%);
  max-width: 100px;
  opacity: 1;
  background-color: #fff;
  width: 50px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  cursor: pointer;
}

.ct-video-player .play-btn img {
  width: 17px !important;
  height: auto !important;
  margin-top: -2px;
  cursor: pointer;
}

.ct-video-player:hover .play-btn {
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

[autoplay] {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .ct-video-player .play-btn {
    display: none;
  }
}
