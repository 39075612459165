.grid-section .sec-title {
  /* font-size: 35px; */
  font-size: min(35px, 2.5vw);
}

.grid-section .sec-disc {
  font-size: 1.4vw;
  color: var(--normal-text);
}

.grid-section .sec-title,
.grid-section .sec-disc {
  margin-top: 40px;
}

.grid-section .sec-item:nth-child(odd) {
  padding-right: 25px;
}

.grid-section .sec-item:nth-child(even) {
  padding-left: 25px;
}

.grid-section .content-col:nth-child(odd) {
  padding-right: 25px;
}

.grid-section .content-col:nth-child(even) {
  padding-left: 25px;
}

.grid-section .content-wrap {
  margin-bottom: 60px;
}

.grid-section .sec-item:last-child .content-wrap {
  margin-bottom: 0;
}
.grid-section .sec-item:nth-last-child(2) .content-wrap {
  margin-bottom: 0;
}

.grid-section a {
  text-decoration: none;
}
