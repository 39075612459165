/* 
    * Custom style sheet
*/

@import url("colors.css");

body {
  font-family: "Onest", sans-serif;
  line-height: 1.2;
}

.list-style-none {
  list-style-type: none;
}

.header-menu li {
  font-size: 30px;
}

.header-menu a {
  text-decoration: none;
  color: var(--normal-text);
}

.header-menu .active.nav-link {
  color: var(--accent-text);
}

.header-menu .nav-link:hover {
  color: var(--accent-text);
}

main {
  padding: 88px 0px 0px;
}

main {
  max-width: 85%;
  margin: 0 auto;
}

footer {
  margin-top: 60px;
  padding: 90px 0px 150px;
  background: #000;
  border-top: 2px solid grey;
}

footer .section-wrap {
  max-width: 85%;
  margin: 0 auto;
}

#header-logo img {
  max-width: 300px;
}

.widget .widget-title {
  font-size: min(3.4vw, 65px);
  color: var(--accent-text);
}

.address-widget,
.address-widget .address-mail,
.address-widget p,
.address-widget address {
  color: var(--normal-text);
  font-size: min(2vw, 26px);
}

.widget p {
  color: #999;
  font-size: 26px;
}

.subscription-form-widget .field-wrap {
  max-width: 432px;
  width: 100%;
  position: relative;
}

.subscription-form-widget input[type="email"] {
  width: 100%;
}

.widget {
  margin: 25px 0 85px;
}

.widget:first-child {
  margin-top: 0px !important;
}

.widget:last-child {
  margin-bottom: 0px;
}

/* .widget #newsLetterForm .field-wrap {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
} */

.subscription-form-widget #email,
.subscription-form-widget [type="submit"] {
  background-color: transparent;
  border: none;
  outline: none;
}

.subscription-form-widget #email {
  border-bottom: 1px solid var(--normal-text);
  padding-bottom: 20px;
}

.subscription-form-widget #newsLetterForm [type="submit"] {
  color: var(--normal-text);
  position: absolute;
  right: 0;
}

.subscription-form-widget #email,
.subscription-form-widget [type="submit"],
.copyright {
  font-size: min(2vw, 26px);
}

.subscription-form-widget #email::placeholder,
.subscription-form-widget #email,
.copyright {
  color: var(--normal-text);
}

.subscription-form-widget #email:focus {
  border-color: #fff;
}

.social-link-widget li {
  margin-bottom: 30px;
  font-size: 26px;
}
.social-link-widget li a {
  color: var(--normal-text);
}

.social-link-widget li a:hover {
  color: var(--accent-text);
  transition: 300ms ease-in;
}

h1 {
  font-size: 65px;
}

#hero-sec {
  max-width: 1024px;
  margin: 0 auto;
}

section {
  padding: 60px 10px;
}

#hero-sec h1 {
  margin: 50px 0 120px;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--accent-text);
  font-weight: 400;
}
p {
  font-weight: 400;
}

ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media (max-width: 1792px) {
  #header-logo img {
    max-width: 250px;
  }
}
