@import url("../../colors.css");

.single-work-page-design-5 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.single-work-page-design-5 .item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.single-work-page-design-5 .item h3 {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 2;
  color: var(--accent-text);
}

.single-work-page-design-5 .type-img-wrap {
  /* background-color: #181818; */
  /* border: 2px solid #222222; */
}

.single-work-page-design-5 img + p {
  margin-top: 15px;
}

.single-work-page-design-5 .type-img-wrap,
.single-work-page-design-5 .type-text-wrap {
  justify-content: center;
}

.single-work-page-design-5 .row {
  padding: 0 10px;
}

.single-work-page-design-5 .col {
  padding: 25px 0;
}

.single-work-page-design-5 .right-pad {
  padding-right: 25px;
}

.single-work-page-design-5 .left-pad {
  padding-left: 20px;
}

.single-work-page-design-5 .col:first-child {
  padding-top: 0px;
}

.single-work-page-design-5 .video-item,
.single-work-page-design-5 .work-video {
  height: 100%;
  /* background-color: var(--dark-cover-bg); */
}

/* .single-work-page-design-5 .video-item video {
} */

.work-video {
  /* background-color: var(--dark-cover-bg); */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.single-work-page-design-5 .text-item {
  font-size: 55px;
}

/* .single-work-page-design-5 p {
  color: #999;
  font-size: 30px;
  margin: 20px auto 0px;
  max-width: 500px;
} */

.edit-text-item .text-table-box p {
  line-height: 1.5;
}
.edit-text-item .text-table-box,
.edit-text-item .text-table-box ~ figure.table {
  width: 60%;
  max-width: 700px;
  margin: 0 auto;
}

.edit-text-item .text-table-box ~ figure.table table {
  width: 100%;
}

.edit-text-item .text-table-box ~ figure.table table,
.edit-text-item .text-table-box ~ figure.table td,
.edit-text-item .text-table-box ~ figure.table tr,
.edit-text-item .text-table-box ~ figure.table th {
  border: none;
  vertical-align: top;
}

.edit-text-item .text-table-box ~ figure.table p {
  margin-bottom: 8px;
}

.edit-text-item p {
  line-height: 1.7;
}
.edit-text-item .left-padding-box {
  max-width: 600px;
  margin-left: 30%;
}

.edit-text-item .between-big-text {
  max-width: 900px;
  margin: 0 auto;
}

.table-full-width-wrap ~ .table th,
.table-full-width-wrap ~ .table td,
.table-full-width-wrap ~ .table tr {
  border: none !important;
}
