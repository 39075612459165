@import url("../../colors.css");

.tabs-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.tabs-nav .parent-sep {
  display: inline-block;
  width: 100px;
  height: 2px;
  border-top: 2px solid var(--normal-text);
  padding-bottom: 0;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.tabs-nav .parent {
  color: var(--accent-text);
}

.tabs-nav .work-category-menu-wrap {
  /* display: flex;
  flex-direction: row;
  list-style-type: none;

  white-space: nowrap;

  padding: 0; */
  width: 82%;
  margin-left: 125px;
  margin-bottom: 0;
  display: flex;
  overflow: hidden;
  overflow-x: hidden;
  padding: 0;
  margin-left: 105px;
}

.work-category-menu-wrap li:first-child a {
  padding-left: 40px;
}

.tabs-nav a {
  color: var(--normal-text);
  font-size: 34px;
}

.tabs-nav a.active {
  color: var(--accent-text);
}

.tabs-nav a:hover,
a:hover {
  color: var(--accent-text);
}

.tabs-nav {
  padding: 120px 0px 50px;
}

/* .tabs-nav .parent {
  margin-right: 15px;
} */

.tabs-nav .parent {
  margin-right: 0px;
}

.tabs-nav .nav-link:first-child a {
  padding-right: 0px;
  padding-left: 0;
}

.tabs-nav .nav-link {
  padding: 0 40px;
  padding-left: 10px;
  user-select: none;
}

/* .tabs-nav li:nth-child(2) {
  margin-left: 124px;
} */

.nav-link:focus,
.nav-link:hover {
  color: #000;
}

.slick-arrow {
  position: relative;
  z-index: 3;
}

.slick-next {
  right: unset !important;
  left: 100%;
  background-color: #fff;
}
.slick-prev {
  left: 0 !important;
  background-color: #fff;
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px;
}

/* .tabs-nav ul {
  scroll-snap-type: x mandatory;
} */

/* .tabs-nav ul li {
  scroll-snap-align: start;
}

.tabs-nav ul::selection {
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
} */

/* scrollbar ends */

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
  opacity: 1 !important;
}

@media (max-width: 1410px) {
  .tabs-nav .nav-item a {
    padding: 0 34px;
  }
}

@media (max-width: 1366px) {
  .tabs-nav a {
    color: var(--normal-text);
    font-size: 28px;
  }
  .tabs-nav .nav-link {
    padding: 0 28px;
  }

  .tabs-nav .nav-link {
    padding: 0 28px;
  }
}
