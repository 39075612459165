.work-grid {
  padding-top: 0;
  padding-bottom: 0;
}

.work-grid .item-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.work-grid-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(11, 500px); */
  /* grid-template-rows: 600px 600px 450px 450px 700px 450px 600px 400px 400px 500px; */

  grid-column-gap: 50px;
  grid-row-gap: 50px;
  margin-top: -50px;
}

.work-grid-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
