.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  clip-path: inset(-45px);
  box-shadow: -60px 15px, -60px 15px, -60px 15px;
  transform: translateY(-15px);
  animation: l19 1.5s infinite linear;
}
@keyframes l19 {
  16.67% {
    box-shadow: -60px 15px #fff, -60px 15px #fff, 19px 15px #fff;
  }
  33.33% {
    box-shadow: -60px 15px #fff, 0px 15px #fff, 19px 15px #fff;
  }
  40%,
  60% {
    box-shadow: -19px 15px #fff, 0px 15px #fff, 19px 15px #fff;
  }
  66.67% {
    box-shadow: -19px 15px #fff, 0px 15px #fff, 60px 15px #fff;
  }
  83.33% {
    box-shadow: -19px 15px #fff, 60px 15px #fff, 60px 15px #fff;
  }
  100% {
    box-shadow: 60px 15px #fff, 60px 15px #fff, 60px 15px #fff;
  }
}
