@import url("colors.css");

.App {
  background-color: #000;
}

body {
  background-color: #000;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

@media (max-width: 767px) {
  .single-work-design-mobile img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 992px) {
  .single-work-design .item:first-child,
  .single-work-design .item:nth-child(2) {
    padding-top: 0;
  }
}
