/* .single-work-design.two-column-design {
  column-count: 2;
  column-gap: 40px;
} */

@import url("../../colors.css");

.single-work-design.two-column-design .item {
  margin: 0;
  display: inline-block;
  margin-bottom: 0px;
  width: 100%;
}

.single-work-design.two-column-design .item {
  margin: 20px 0;
  position: relative;
}

.single-work-design.two-column-design .item h3 {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 2;
  color: var(--accent-text);
}

.single-work-design.two-column-design .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-work-design p {
  color: var(--normal-text);
  max-width: 500px;
  margin: 50px auto 60px;
  font-size: 30px;
}

.single-work-page .single-work-design {
  padding-top: 0;
}

.single-work-page .column:nth-child(odd) section {
  padding-right: 20px;
}
.single-work-page .column:nth-child(even) section {
  padding-left: 20px;
}

/* Text item styling starts */
.single-work-page-design-1 .text-item {
  font-size: 55px;
}

/* .single-work-page-design-3 p {
  color: var(--normal-text);
  font-size: 30px;
  margin: 20px auto 0px;
  max-width: 500px;
} */

.edit-text-item .text-table-box p {
  line-height: 1.5;
}
.edit-text-item .text-table-box,
.edit-text-item .text-table-box ~ figure.table {
  width: 60%;
  max-width: 700px;
  margin: 0 auto;
}

.edit-text-item .text-table-box ~ figure.table table {
  width: 100%;
}

.edit-text-item .text-table-box ~ figure.table table,
.edit-text-item .text-table-box ~ figure.table td,
.edit-text-item .text-table-box ~ figure.table tr,
.edit-text-item .text-table-box ~ figure.table th {
  border: none;
  vertical-align: top;
}

.edit-text-item .text-table-box ~ figure.table p {
  margin-bottom: 8px;
}

.edit-text-item p {
  line-height: 1.7;
}
.edit-text-item .left-padding-box {
  max-width: 600px;
  margin-left: 30%;
}

.edit-text-item .between-big-text {
  max-width: 900px;
  margin: 0 auto;
}

/* Text item styling ends */
