@import url("../../colors.css");

.hamburger-icon .bar {
  width: 45px;
  display: block;
  height: 2px;
  background: #000;
  margin: 7px 10px;
}

.icon-wrap {
  width: 75px;
  height: 75px;
  background: var(--accent-bg);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
