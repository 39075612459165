.eighty-per-box {
  max-width: 80%;
}

.ct-video-player video {
  object-fit: contain;
  max-height: 580px;
  width: 100%;
  height: 100%;
}

.item > img {
  max-height: 580px;
}
